import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../rest.service';

@Component({
  selector: 'verify-address',
  templateUrl: './verify-address.component.html',
  styleUrls: ['./verify-address.component.scss']
})
export class VerifyAddressComponent implements OnInit {
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<VerifyAddressComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService,
    public _restService: RestService) {
  }

  ngOnInit(): void {

  }

  addressUse(status): void {
    if (status == true) {
      this._enrollSession.data.RetailOrderSummary.shippingFormData.address1 = this.data.suggestedAddress.address1;
      this._enrollSession.data.RetailOrderSummary.shippingFormData.City = this.data.suggestedAddress.city;
      this._enrollSession.data.RetailOrderSummary.shippingFormData.State = this.data.suggestedAddress.state;
      this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode = this.data.suggestedAddress.countryCode;
      this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode = this.data.suggestedAddress.zip;
      this.dialogRef.close({ continue: status });
    }
    else{
      this.dialogRef.close({ continue: status });
    }
  }
    continueWithAddress(status): void {
        this.dialogRef.close({ continue: status });
    }
}
