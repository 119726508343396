import { Injectable } from "@angular/core";
import { EnrollSessionService } from "./enrollSession.service";
import { AuthService } from "./auth.service";
import { RestService } from "./rest.service";
import { SharedMethodService } from "./sharedMethod.service";
import { API_URL,LinkTracking } from "app/constants";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CalculateRetailOrderService } from "./calculateRetailOrder.service";
import * as moment from "moment";
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    isDiscountExist:any;
    totalRetailPrice:any;
    public hasLower: boolean = false;
    public hasUpper: boolean = false;
    public hasNum: boolean = false;
    public hasSpecial: boolean = false;
    public hasLength: boolean = false;
    public hasCardNumberCheck: boolean = false;
    SubscribeClickCheck: boolean = false;
    EmailCheck: boolean = false;
    
    constructor(
    public _enrollSession: EnrollSessionService,
    private _auth: AuthService,
    public _restService: RestService, 
    public _sharedMethodService: SharedMethodService,
    public router: Router,
    private _toastr: ToastrService,
    public _calculateRetailOrderService: CalculateRetailOrderService
    ){}
    
    getImage(id)
  {
    this._enrollSession.customerImage =  API_URL +"/NonProfitEnrollment/GetCustomerImageData?customerId="+id || '../../../assets/images/avatars/profile_icon.png';
  }
   
    CreateAutoOrder(itemDetail, type, CustomerID): void {
        let req = {
            "AutoOrderRequest": {
                "AutoOrderCartID": "",
                "AutoShipStartDate": type == "2Month" ? moment().add(1, 'months').format('MM/DD/YYYY') : moment().add(3, 'months').format('MM/DD/YYYY'),
                "AutoShipStopDate": type == "2Month" ? moment().add(2, 'months').add(1, 'days').format('MM/DD/YYYY') : "",
                "AutoshipType": 0,
                "BeginMonth": 0,
                "Details": itemDetail ? itemDetail : [],
                "ShipMethodID": this._enrollSession.data.Order_shipMethodIDField,
                "AutoshipId":  "",
            },
              'Company': this._enrollSession.data.RetailOrderSummary.customerInfoData.CompanyName || '',
            "CustomerType": 22,
            "Email": this._enrollSession.data.RetailOrderSummary.customerInfoData.Email ? this._enrollSession.data.RetailOrderSummary.customerInfoData.Email : '',
            "ShippingAddress": {
                  'Address1': this._enrollSession.data.RetailOrderSummary.customerInfoData.address1,
                  'City': this._enrollSession.data.RetailOrderSummary.customerInfoData.City,
                  'CountryCode': this._enrollSession.data.RetailOrderSummary.customerInfoData.CountryCode,
                  'State': this._enrollSession.data.RetailOrderSummary.customerInfoData.State,
                  'Zip': this._enrollSession.data.RetailOrderSummary.customerInfoData.ZipCode,
                  'FirstName': this._enrollSession.data.RetailOrderSummary.customerInfoData.FirstName,
                  'LastName': this._enrollSession.data.RetailOrderSummary.customerInfoData.LastName,
                  'BusinessName': this._enrollSession.data.RetailOrderSummary.customerInfoData.CompanyName || '',         
                  'Address2': this._enrollSession.data.RetailOrderSummary.customerInfoData.address2,
                  'Address3': ''
            },
            'FirstName': this._enrollSession.data.RetailOrderSummary.customerInfoData.FirstName || '',
            'LastName': this._enrollSession.data.RetailOrderSummary.customerInfoData.LastName || '',
            "Phone1": this._enrollSession.data.RetailOrderSummary.customerInfoData.Phone,
            "CustomerId": CustomerID,
            "AutoOrderID": 0,
            "PaymentType": "PrimaryCreditCard"
        }
        this._restService.CreateAutoOrder(req).subscribe((res: any) => {
            if (res) {
                if (res.AutoOrderID) {
                    this.placeOrderTracking("Autoship", res, CustomerID);
                    localStorage.setItem("orderDetails" + type, JSON.stringify(res));
                    if (type === "4Month") {
                        this._enrollSession.submitApplicationData.FourMonthAutoship = res.AutoOrderID;
                        this.router.navigate(['/Confirmation']);
                    }
                    else {
                        this._enrollSession.submitApplicationData.TwoMonthAutoship = res.AutoOrderID;
                    }
                } else {
                    this._toastr.error(res.Result.errorsField);
      
                }
            }
            else {
      
                this._toastr.error('Something went wrong please try again');
            }
      
        }, (err) => {
            this._toastr.error('Something went wrong please try again', 'Error');
      
        }, () => {
      
        });
      
      }
    getDiscountTotal() {
        let Enrollpack = this._enrollSession.data?.RetailOrderSummary?.Enrollpack || [];
        let InitialOrders = this._enrollSession.data?.RetailOrderSummary?.InitialOrders || [];
        this.isDiscountExist = Enrollpack.find(item => item.ItemCode == "7800GC");
        this.totalRetailPrice = 0;
        if (Enrollpack.length > 0) {
            Enrollpack.forEach((item) => {
                if (item.Price > 0) {
                    this.totalRetailPrice += item.RetailPrice * item.Quantity;
                }
            });
        }
        if (InitialOrders.length > 0) {
            InitialOrders.forEach((item) => {
                if (item.Price > 0) {
                    this.totalRetailPrice += (item.Other1Price || item.RetailPrice) * item.Quantity;
                }
            });
        }
        let subtotal = this._enrollSession.data?.RetailOrderSummary?.InitialOrderDetails?.SubTotal;
        if (this.isDiscountExist) {
            return 50;
        }
        else if (subtotal < this.totalRetailPrice) {
            return this.totalRetailPrice - subtotal;
        }
        else {
            return 0;
        }
    }
    getDiscountTotalForSmartOrder(){
        let SmartOrders = this._enrollSession.data?.RetailOrderSummary?.SmartOrders || [];
        this.isDiscountExist = SmartOrders.find(item => item.ItemCode == "7800GC");
        this.totalRetailPrice = 0;
        if (SmartOrders.length > 0) {
            SmartOrders.forEach((item) => {
                if (item.Price > 0) {
                    this.totalRetailPrice += item.RetailPrice * item.Quantity;
                }
            });
        }
        let subtotal = this._enrollSession.data?.RetailOrderSummary?.SmartOrderDetails?.SubTotal;
        if (this.isDiscountExist) {
            return 50;
        }
        else if (subtotal < this.totalRetailPrice) {
            return this.totalRetailPrice - subtotal;
        }
        else {
            return 0;
        }
    }

    isLoginCartButton(){
        if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true"){
            return true;
        }
        else{
            return false;
        }
    }

    validatePassword(password?: string) {
        if (password !== undefined && password !== null) {
            const lowercaseRegex = new RegExp("(?=.*[a-z])");
            if (lowercaseRegex.test(password)) {
                this.hasLower = true;
            } else {
                this.hasLower = false;
            }

            const uppercaseRegex = new RegExp("(?=.*[A-Z])"); 
            if (uppercaseRegex.test(password)) {
                this.hasUpper = true;
            } else {
                this.hasUpper = false;
            }

            const numRegex = new RegExp("(?=.*\\d)");
            if (numRegex.test(password)) {
                this.hasNum = true;
            } else {
                this.hasNum = false;
            }

            const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
            if (specialcharRegex.test(password)) {
                this.hasSpecial = true;
            } else {
                this.hasSpecial = false;
            }

            const passwordLength = password?.length;
            if (passwordLength >= 8) {
                this.hasLength = true;
            } else {
                this.hasLength = false;
            }
        }
    }

    getItemPrice(product) {

        var item = _.filter(this._enrollSession.data.RetailOrderProductDetail, (item) => {
            return (item.ParentItemCode == product.ItemCode || !item.ParentItemCode) && (item.ItemCode == product.ItemCode || item.ItemCode == (product.ItemCode + '-P65'));
        });
        return item.length > 0 ? item[0].PriceEach : 0;
    }
    checkAddress(req, res) {
        return req.address1 == res.address1 && req.city == res.city && req.state == res.state && req.countryCode == res.countryCode && req.zip == res.zip;
    }
    validateCardNumber(cardNumber?: any) {
        let firstdigit = cardNumber?.charAt(0);
        if(firstdigit === '2' || firstdigit === '3' || firstdigit === '4' || firstdigit === '5' || firstdigit === '6'){
            this.hasCardNumberCheck = true;
        }
        else{
            this.hasCardNumberCheck = false;
        }
    }
    placeOrderTracking(OrderType: string, Response, CustomerID?) {
        try {
            const items = [];
            let orderData = {};

            if(OrderType == "Autoship"){
                const { Details, AutoOrderID, SubTotal, Total } = Response;
                Details.forEach((product: any) => {
                    items.push({
                        "itemId": product.ItemCode,
                        "productName": product.Description,
                        "quantity": product.Quantity,
                        "qv": product.BusinesVolume,
                        "cv": product.CommissionableVolume,
                        "price": product.PriceEach,
                    });
                });
                orderData = [{
                    "orderId": AutoOrderID,
                    "customerId": CustomerID || 0,
                    "sponsorId": this._enrollSession.data?.RetailEnroll?.EnrollerId || 0,
                    "orderDetail": {
                      "items": items,
                      "subTotal": SubTotal || 0,
                      "totalAmount": Total || 0
                    }
                }];
            } else {
                orderData = [{
                    "orderId": Response.OrderID,
                    "customerId": Response.CustomerID,
                    "sponsorId": this._enrollSession.data?.RetailEnroll?.EnrollerId || 0,
                    "orderDetail": {
                      "items": items,
                      "subTotal": 0,
                      "totalAmount": 0
                    }
                }];
            }

            const tempData = JSON.stringify(orderData);
            window['ZTM'].setStats(LinkTracking.companyName, "PlaceOrder", tempData);

        } catch (error) {

        }
    }

    createCustomerTracking(CustomerID: number) {
        try {
        if(!CustomerID) {
            return;
        }
        this._restService.GetCustomers(CustomerID)
        .subscribe((result: any) => {
            console.log(result);
                const { Customer } = result;
                const createCustomerRequest = [{
                  "customerId": Customer.customerIDField,
                  "emailaddress": Customer.emailField,
                  "phoneNumber": Customer.phoneField,
                  "firstName": Customer.firstNameField,
                  "lastName": Customer.lastNameField,
                  "customerStatus": Customer.customerStatusField,
                  "customerType": Customer.customerTypeField,
                  "username": Customer.usernameField || Customer.loginNameField || "",
                  "webAlias": Customer.webAliasField,
                  "sponsorId": Customer.sponsorIDField,
                  "enrollerId": Customer.enrollerIDField,
                  "signupDate": Customer.createdDateField,
                  "primaryAddress": {
                    "id": 0,
                    "line1": Customer.mainAddress1Field,
                    "line2": Customer.mainAddress2Field,
                    "line3": Customer.mainAddress3Field,
                    "city": Customer.mainCityField,
                    "state": Customer.mainStateField,
                    "zip": Customer.mainZipField,
                    "countryCode": Customer.mainCountryField,
                  },
                  "defaultShippingAddress": {
                    "id": 0,
                    "line1": Customer.mainAddress1Field,
                    "line2": Customer.mainAddress2Field,
                    "line3": Customer.mainAddress3Field,
                    "city": Customer.mainCityField,
                    "state": Customer.mainStateField,
                    "zip": Customer.mainZipField,
                    "countryCode": Customer.mainCountryField,
                  },
                  "externalReferenceId": Customer.externalReferenceIdField,
                  "languageCode": Customer.languageIDField,
                }];
                window['ZTM'].setStats(LinkTracking.companyName, 'CreateCustomer', JSON.stringify(createCustomerRequest));
            });
        } catch (error) {
          console.log(error);
        }
    }

    createCustomer(customerType?,newsLetterRequest?,preference?){
        let CustomerInfoData = this._enrollSession.data.RetailOrderSummary.customerInfoData;
        let AccountInfoData = this._enrollSession.data.RetailOrderSummary.accountInfoData;
        let ShippingFormData = this._enrollSession.data.RetailOrderSummary.shippingFormData;
        this._enrollSession.data.customertype = customerType || 22;
        const promise = new Promise((resolve,reject) => {
            let request : any = 
            {
                "FirstName": CustomerInfoData.FirstName,
                "LastName": CustomerInfoData.LastName,
                "Company": this._enrollSession.data.RetailOrderSummary.customerInfoData?.CompanyName || '',
                "CustomerType": this._enrollSession.data.customertype,
                "CustomerStatus": 0,
                "Email" : CustomerInfoData.Email,
                "Phone": CustomerInfoData.Phone,
                "Phone2": "",
                "MobilePhone": CustomerInfoData.Phone,
                "Fax": "",
                "Notes": "",
                "MainAddress1": CustomerInfoData?.address1 || '',
                "MainAddress2": CustomerInfoData?.address2 || '',
                "MainCity": CustomerInfoData?.City || '',
                "MainState": CustomerInfoData?.State || '',
                "MainZip" : CustomerInfoData?.ZipCode || '',
                "MainCountry": CustomerInfoData?.CountryCode || '',
                "MainCounty": "",
                "MailAddress1": ShippingFormData?.address1 || '',
                "MailAddress2": ShippingFormData?.address2 || '',
                "MailCity": ShippingFormData?.City || '',
                "MailState": ShippingFormData?.State || '',
                "MailZip": ShippingFormData?.ZipCode || '',
                "MailCountry": ShippingFormData?.CountryCode || '',
                "MailCounty": "",
                "OtherAddress1": "",
                "OtherAddress2": "",
                "OtherAddress3": "",
                "OtherCity": "",
                "OtherState": "",
                "OtherZip": "",
                "OtherCountry": "",
                "OtherCounty": "",
                "CanLogin": newsLetterRequest === true ? false : true,
                "LoginName": AccountInfoData?.loginName || '',
                "LoginPassword" : AccountInfoData?.Password || '',
                "InsertEnrollerTree": true,
                "EnrollerID": this._enrollSession.data.RetailEnroll.EnrollerId ? this._enrollSession.data.RetailEnroll.EnrollerId : '',
                "InsertUnilevelTree": true,
                "SponsorID": this._enrollSession.data.RetailEnroll.SponsorId ? this._enrollSession.data.RetailEnroll.SponsorId : '',
                "UseManualCustomerID": false,
                "ManualCustomerID": 0,
                "TaxID": "",
                "SalesTaxID": "",
                "SalesTaxExemptExpireDate": "",
                "IsSalesTaxExempt": true,
                "BirthDate": "",
                "Field1": "",
                "SubscribeToBroadcasts": true,
                "SubscribeFromIPAddress": "",
                "CurrencyCode": this._enrollSession.data.RetailEnroll.CurrencyCode ? this._enrollSession.data.RetailEnroll.CurrencyCode : '',
                "PayableToName": "",
                "EntryDate": "",
                "DefaultWarehouseID": 0,
                "PayableType": 0,
                "CheckThreshold": 0,
                "TaxIDType": "",
                "LanguageID": 0,
                "Gender": 0,
                "VatRegistration": "",
                "MiddleName": "",
                "NameSuffix": "",
                "BinaryPlacementPreference": 0,
                "UseBinaryHoldingTank": true,
                "MainAddressVerified": true,
                "MailAddressVerified": true,
                "OtherAddressVerified": true,
                "EnrollerKey": this._enrollSession.data.ReferredBy ? this._enrollSession.data.ReferredBy : ''
            }
            this._restService.CreateCustomer(request).subscribe((result:any) => {
                if(result){
                    this.SubscribeClickCheck = true;
                    this._enrollSession.data.CustomerID = result.customerIDField;
                    if(this._enrollSession.data.CustomerID > 0){
                        let req = {}
                        preference.forEach((ele)=>{
                            req[`${ele.id}`] = ele.isChecked?"1":"0"
                        })
                        req['CustomerId'] = this._enrollSession.data.CustomerID
                        this._restService.createCustomerExtended(req).subscribe((res:any)=>{
                            if(res){
                                return true;
                            }
                        },(err)=>{
                            this._toastr.error("Error","Something went wrong while saving preferences")
                        })
                    }
                    if(this._enrollSession.data.RetailOrderSummary?.InitialOrders?.length > 0){
                        this.abandonedCart("Standard", this._enrollSession.data.RetailOrderSummary.InitialOrders,this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total)
                    }
                    if(this._enrollSession.data.RetailOrderSummary?.Enrollpack?.length > 0){
                        this.abandonedCart("Standard", this._enrollSession.data.RetailOrderSummary.Enrollpack,this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total)
                    }
                    if(this._enrollSession.data.RetailOrderSummary?.SmartOrders?.length > 0){
                        this.abandonedCart("Standard", this._enrollSession.data.RetailOrderSummary.SmartOrders,this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total)
                    }
                    resolve(result);
                }
                else{
                    this._toastr.error('Error', 'There is something wrong with customer data.');
                    this.SubscribeClickCheck = false;
                    reject();
                }
            },
            error => {
                this._toastr.error('Error', 'There is something wrong with customer data.');
                this.SubscribeClickCheck = false;
                reject();
            });
        });
        return promise;
    }
    public abandonedCart(type: string, items: Array<any>, total: number|string){
        try {
            const cartItems:any = [];
            if (items.length > 0) {
                items.forEach(element => {
                    cartItems.push({
                    "itemId": element.ItemCode,
                    "productName": element.Description,
                    "quantity": element.Quantity,
                    "qv": element.BV,
                    "cv": element.CV,
                    "price": element.Price,
                    });
                });
            };

            let abandonedCartRequest: any = {
                "type": type,
                "cartItem": cartItems,
                "price": total,
                "enrollerInfo": {
                    "customerId": this._enrollSession.data.CustomerID,
                    "webAlias": this._enrollSession.data?.RetailEnroll?.webAliasField || this._enrollSession.data.CustomerWebAlias || this._enrollSession.data.ReferredBy
                },
                "customerInfo": {}
            };
            abandonedCartRequest = [{ ...abandonedCartRequest}];

            const tempData = JSON.stringify(abandonedCartRequest);
            window['ZTM'].setStats(LinkTracking.companyName, 'AbandonedCart', tempData);
        } 
        catch (error) {   
            console.error("Abandoned Cart Error", error);
        }
    }
}